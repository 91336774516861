.App {
  text-align: center;
}

html {
  height: 100%;
}
img.spinner {
  height: 50px;
  width: 50px;
  animation-name: spin;
  animation-duration: .75s;
  animation-iteration-count: infinite;
}

@keyframes spin{
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.StaleStatus {
  background-color: #cacdd1;
}
.__react_component_tooltip {
  opacity: 1 !important;
  border-color: black;
  border-width: 1px;
  border-style: solid;
}
.__react_component_tooltip.show.place-top.type-light::after {
  border-color: black;
  border-width: 0px;
  border-style: solid;
}
body {
  font-family: 'Libre Franklin', sans-serif !important;
  position: relative;
  padding-bottom: 15rem;
  min-height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
table tbody tr td.hname {
  text-align: left !important;
}
span.check {
  content: "\2713";
}
span.xed {
  content: "\2718";
}
@keyframes eptp{
  from {background-color: #8e0202;}
  to {background-color: #ff0000;}
}
@keyframes diversion {
    from {
        background-color: #727272;
        color:black;
    }
    to {
        background-color: black;
        color: white;
    }
}
@keyframes closed {
  from {
    background-color: #8f2ecc;
    color:black;
  }
  to {
        background-color: #5f1f87;
        color: white;
  }
}
.whiteText {
  color:white !important;
}
.diversion {
  color:white;
  animation-name: diversion;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}
.diversion-static {
  background-color:black;
  color:white;
}
.closed-static{
  color: white;
  background-color: #5f1f87;
}
.scroller {
  height: 450px;
  overflow-y: scroll;
}
.dropdown-link {
  color:black !important;
}
.dropdown-link:hover {
  border-left-style: solid;
  border-left-color: #00A3E0;
  border-left-width: 2px;
}
button.dropdown-link{
  color: inherit;
  background-color: inherit;
}
a.dropdown-link:active {
  background-color: inherit;
}
.closed {
  color: white;
  animation-name: closed;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}
.cotsBlue {
  background-color: rgba(0, 163, 224, 1);
  color:white !important;
}
.cotsBlueFaded {
  background-color: rgba(0, 163, 224, 0.1);
  color:white
}
.eptp{
  animation-name: eptp;
  animation-duration:1s;
  animation-iteration-count: infinite;
}
.footer {
  position:absolute;
  bottom:0;
  width: 100%;
}

.footer a h6{
  color: white;
  padding-top: 10px;
}
.darkModeGray {
  background-color: #949596;
}
#RtasNavEPTP {
  animation-name: eptp;
  animation-duration:1s;
  animation-iteration-count: infinite;
}
.standard-height {
  min-height: 600px;
}
.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}
.mobileclick {
  cursor:pointer;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.box-shadow {
  box-shadow: 0px 3px 15px rgba(0,0,0,0.2);
  border-radius: 20px;

}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  table tbody tr {
    font-size: 12px;
    line-height: 14px;
  }
  table tbody tr td {
    vertical-align:  !important;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  table tbody tr {
    font-size: 12px;
    line-height: 14px;
  }
  table tbody tr td {
    vertical-align:  !important;
  }

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  table tbody tr {
    font-size: 12px;
    line-height: 14px;
  }
  table tbody tr td {
    vertical-align:  !important;
    padding-top: 10px !important;
    padding-bottom:10px !important;
  }
}
.pointer {cursor: pointer;}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  table tbody tr {
    font-size: 12px;
    line-height: 12px;
  }
  table tbody tr td {
    vertical-align:  !important;
    padding-top: 8px !important;
    padding-bottom:8px !important;
  }
}
table thead th {
  padding-top: 6px !important;
  padding-bottom:6px !important;
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

}

.map-marker {
	border: 1px solid black;
}
.map-marker:hover {
	z-index: 2040;
	border: 1px solid yellow;
	cursor: default;
}
.map-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
}

.username {
  width: 100%;
  padding-right: 1rem;
  text-align: right;
  color: #888;
}

.test-banner {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 0;
  z-index: 1020;

  background-color: yellow;
  color: black;
}

.refresh-banner {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 0;
  z-index: 1022;

  background-color: #28a745;
  color: white;
}

@media screen and (max-width: 400px) {
    .refresh-banner {
      font-size: 12px;
    }

    .test-banner {
      font-size: 12px;
    }
}

.sort-button {
  margin-left: 10px;
  color: white;
  background-color: #007bff;
  border: 1px solid white;
  border-radius: 5px;
  box-shadow: 1px 1px 1px black;
  transition: background-color 0.3s;
}
.sort-button:hover {
  background-color: #209bff;
}

.contact-list-filter-panel {
  margin-bottom: 10px;
}
.filter-checkbox {
margin-right: 5px;
}
.w100 {
  width: 100%;
}
.w75 {
  width: 75%;
}
.collapsed {
  display: none;
}
.text-left {
  text-align: left;
}
.p-0 {
    padding: 0;
}
.loading-panel {
    height: 30px;
}

td.facility-alert {
    background: #ff8214;
    text-align: left;
    font-weight: bold;
}

.ed-comment-icon {
    font-size: 24px;
    padding-left: 4px;
    font-weight: bold;
    cursor: pointer;
}

#StatusBody table td {
    vertical-align: middle;
}
