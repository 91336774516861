code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.mobleclick {
  cursor:pointer;
}

.small-font {
    font-size: 12px;
}
